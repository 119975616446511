.object_step {
  @apply pl-4 border-l border-zinc-300 mt-1 mb-1;
}

.object_step .object_step {
  @apply pl-0 border-none mt-1 mb-1;
}

.selectorForm {
  > div > div > .object_step {
    padding-left: 0;
    border: none;
  }
  .object_step .object_step .object_step {
    border-left: solid 1px;
    @apply pl-4 border-zinc-300;
  }
}

.nice-json-form-debug {
  display: none;
  border: 1px solid #ff9999;
  background: #ffdddd;
  margin: 2px;
  padding: 2px;
}

.nice-json-form-debug__component {
  display: block;
  background: #ff9999;
}
.nice-json-form-debug__schemaFragment {
  display: none;
}
.nice-json-form-debug__value {
  display: none;
}
