body {
  background-color: rgb(220, 221, 223);
}

// -----  random ------
.info-pop {
  z-index: 900;
}

.fileitemmenu {
  z-index: 901;
}

.spinner {
  border-top-color: white;
  height: 15px;
  width: 15px;
  border-width: 2.5px;
}

.spinner-lg {
  border-top-color: white;
  height: 70px;
  width: 70px;
  border-width: 7px;
}

.custom-alert {
  position: fixed;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.custom-alert.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.message {
  &__backdrop {
    z-index: 1000;
  }
  &__panel {
    z-index: 1002;
  }
}

// -----  toolbar ------
.toolbar {
  transition: height 0.4s ease-in-out;
}

// -----  workspace ------

.workspace {
  height: auto;
  bottom: 0;
  overflow: hidden;
}

.layout .react-grid-item {
  transition: width 0.4s ease-in-out; // smooth effect on grid items in workspace
}

.sidepanel-container {
  z-index: 900;
}

.workspace-container {
  flex-grow: 1;
}

.node-info-pane-content {
  height: 92%;
}

.manual-edits-menu {
  z-index: 1000;
}

// -----  plots ------

.js-plotly-plot {
  width: inherit;
  height: inherit;
}
.plot-container {
  &--outer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &--wrapper {
    width: 100%;
    flex-grow: 1;
  }
  &--inner {
    height: 100%;
    width: 100%;
  }
}

.logs-container {
  overflow: hidden;
  max-height: 300px;
  overflow: scroll;
  &--content {
    width: auto;
    max-width: 1000px;
  }
}

.logs-container.expanded {
  max-height: none; /* Remove the max-height restriction */
}

.logs-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(transparent, rgba(255, 255, 255, 0.9));
  pointer-events: none;
  border-radius: 8px;
}

.progress-plot-container .js-plotly-plot .plotly .modebar {
  top: -2rem !important;
}
.js-plotly-plot .plotly .modebar {
  right: 0.5rem !important;
  top: 0rem !important;
}

.js-plotly-plot .plotly .modebar-group {
  display: flex !important;
}
.subplot-editor {
  transition: ease-in-out 30ms;
}
div.plotly-notifier {
  visibility: hidden;
}
