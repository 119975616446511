@import "variables";
@import "mixins";

.processing-jsoneditor {
  @include hide-root-elements;

  // all panels
  & div[data-theme="html"] .je-indented-panel {
    border: none;
    padding: 0;
    margin: 0;

    // outer panel
    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      flex-wrap: wrap;
    }

    // single panel
    & > div > .je-indented-panel {
      padding: 0.5rem;
      border: $border-style;
      border-radius: $border-radius;
      background-color: rgba(255, 255, 255, 0.527);

      // panel footer styles (wrapper around delete & move buttons)
      & span {
        display: flex;
        justify-content: space-between;
        height: 1.2rem;
      }

      & // title
        div
        div.je-child-editor-holder.je-object__container
        div.je-indented-panel
        div
        div
        div.row
        div.je-object__container
        div.je-indented-panel
        div
        div
        div.row
        div.je-object__container
        h3.je-header.je-object__title {
        position: relative;
        width: fit-content;
        margin: 0px !important;
      }

      @include panel-layout-styles;
      @include toggle-button-styles;
      @include hide-processing-single-panel-elements;
      @include upload-input-styles;

      & // object labels
        div
        div.je-child-editor-holder.je-object__container
        div.je-indented-panel
        div
        div
        div.row
        div.je-object__container
        h3.je-header.je-object__title
        label {
        display: block;
      }

      // main help description
      &
        > div
        > div.je-child-editor-holder.je-object__container
        > div.je-indented-panel
        > div
        > div
        > div.row
        > div.je-object__container
        > h3.je-header.je-object__title
        label {
        @include common-description-style($ui-color-border);
        position: absolute;
        margin-left: -460px;
        margin-top: 0.2rem;
      }

      & div.je-child-editor-holder .je-indented-panel .je-object__container p {
        @include common-description-style($ui-color-border);
        text-transform: lowercase;
        margin-left: -0.5rem;
        margin-top: -1.7rem;
      }

      // help description
      & div.je-child-editor-holder .je-indented-panel .form-control > p {
        @include common-description-style($ui-color-border);
        text-transform: lowercase;
        margin-left: -0.5rem;
        margin-top: -0.3rem;
      }
    }
  }

  // input labels
  & .je-form-input-label,
  label {
    @include input-label-style;
    margin-bottom: 0px !important;
    font-size: $font-size-small;
    margin-left: 1.6rem;
  }

  // input & label wrapper
  & .form-control {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  // inputs
  input {
    @include input-style;
  }

  // select
  & select.je-switcher,
  select {
    @include input-style;
    width: 350px;
  }

  // move down button
  & .json-editor-btn-movedown.movedown.json-editor-btntype-move {
    @include move-button-style(-90deg);
  }

  // move up button
  & .json-editor-btn-moveup.moveup.json-editor-btntype-move {
    @include move-button-style(90deg);
  }

  // delete btn
  .json-editor-btn-delete.delete.json-editor-btntype-delete {
    font-size: $font-size-small;
    visibility: hidden;
    margin-right: auto;
    margin-left: -0.3rem;
    color: #222222b3;

    &::before {
      visibility: visible;
      content: "Remove";
      font-size: inherit;
    }
    &:hover {
      color: #222;
    }
  }

  // add button
  & .json-editor-btn-add.json-editor-btntype-add {
    color: $ui-color;
    box-shadow: none !important;
    font-size: $font-size-small;
    visibility: hidden;
    position: relative;
    margin-top: 0.5rem !important;
    width: 120px;
    &::after {
      content: "+ Add item";
      visibility: visible;
      position: absolute;
      width: fit-content;
      padding: 0.2rem 0.6rem;
      border: dashed 1px $ui-color;
      border-radius: $border-radius;
      top: 0;
      left: 0;
    }
    &:hover {
      color: darken($ui-color, 10%);
    }
  }
}
