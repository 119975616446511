@import "variables";
@import "mixins";

.import-form-note {
  margin-top: -2rem;
  padding: 0.3rem 0.2rem;
  border-bottom: solid 1px rgba(209, 213, 219, 0.7);
  padding-bottom: 1rem;
}

.import-jsoneditor {
  @include upload-input-styles;

  & // hide descriptions / errors
    > div
    > div
    > div.je-ready
    > div
    > div
    > div.je-child-editor-holder.je-object__container
    > p,
  > div
    > div
    > div.je-ready
    > div
    > div
    > div.je-child-editor-holder.je-object__container
    > div
    > p {
    display: none;
  }

  & // outer panel 
    div
    div
    div.je-ready
    div
    div
    div.je-child-editor-holder.je-object__container
    div.je-indented-panel {
    padding: 0;
    margin: 0;

    // next panel
    & div div div.row div.je-object__container div.je-indented-panel {
      margin: 0 !important;
    }
  }

  // input labels
  & .je-form-input-label,
  label {
    @include input-label-style;
    margin-bottom: 0px !important;
    margin-left: 1.6rem;
  }

  & .form-control {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    & p {
      @include common-description-style($ui-color-border);
    }
  }

  // inputs
  input {
    @include input-style;
  }

  // select
  & select.je-switcher,
  select {
    @include input-style;
    width: 200px;
  }

  // projection input
  & .projection-select {
    width: 350px !important;
    max-width: 350px !important;
  }

  // select option for projection input
  & .select-options {
    width: 500px !important;
  }

  // style and add system type label
  > div {
    font-size: 0.85rem;
    font-weight: 500;
    &::before {
      margin-bottom: 3rem;
      content: "System type";
    }
    .je-switcher {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  div .je-ready > div[data-schemapath="root"] > .je-form-input-label, // root label
  .json-editor-btn-collapse.json-editor-btntype-toggle, // toggle buttons
  .je-child-editor-holder.je-object__container 
  > .je-indented-panel .je-object__container 
  > .je-header.je-object__title, // pipeline title
  .je-child-editor-holder.je-object__container .je-object__controls {
    display: none;
    visibility: hidden;
  }

  // remove borders
  .je-child-editor-holder.je-object__container
    > .je-indented-panel
    .je-object__container
    .je-indented-panel,
  .je-child-editor-holder.je-object__container > .je-indented-panel {
    border: none;
  }

  // add margin to "form"
  .je-child-editor-holder.je-object__container
    > .je-indented-panel
    .je-object__container
    .je-indented-panel {
    margin: 1rem 0rem !important;
  }

  // add gaps between "form items"
  .je-child-editor-holder.je-object__container
    > .je-indented-panel
    .je-object__container
    .je-indented-panel
    > div
    > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .je-block-link {
    font-size: 0.9rem;
    font-weight: 400;
    color: rgb(55 65 81);
    margin: 0rem 0.2rem;
    margin-bottom: 0.5rem;
    border: dashed 1px #94a3b8;
    padding: 0.4rem;
    border-radius: 0.3rem;
    width: fit-content;
    &::before {
      pointer-events: none;
      content: "Upload completed";
      margin-right: 0.5rem;
    }
  }
}
