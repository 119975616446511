//General form styles

.form-label {
  font-size: 0.875rem;
}

// Specific form styles

.create-group-form {
  margin-top: 0rem;
  margin-left: 19rem;
}

.update-group-form {
  margin-top: -7rem;
  margin-left: 19rem;
}

.save-manual-edit-form,
.save-layout-form {
  z-index: 1000;
}

/* Hide arrows in input number fields */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield;
}
