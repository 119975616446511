.ql-container.ql-snow {
  border: none !important;
}
.ql-toolbar.ql-snow {
  border: 1px solid rgb(203, 213, 225) !important;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  padding: 4px !important;
}
.ql-editor {
  border: 1px solid rgb(203, 213, 225) !important;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-top: none !important;
  padding: 0.375rem, 0.75rem !important;

  &::before {
    color: rgb(148, 163, 184) !important;
    font-style: normal !important;
    font-size: 0.875rem !important;
  }
}
.ql-editor p {
  font-size: 0.875rem !important;
}
.ql-snow.ql-toolbar button {
  height: 22px !important;
}
