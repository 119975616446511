@import "variables";
@import "mixins";

.inversion-jsoneditor {
  @include hide-root-elements;

  &
    > div
    > div
    > div.je-ready
    > div
    > div
    > div.je-child-editor-holder.je-object__container
    > h3.je-header.je-object__title
    > button.json-editor-btn-collapse.json-editor-btntype-toggle,
  div
    > div
    > div.je-ready
    > div
    > div
    > div.je-child-editor-holder.je-object__container
    > div.je-indented-panel
    > div
    > div
    > div
    > div
    > .je-header.je-object__title {
    display: none;
  }

  // top label
  & > div > div > div.je-ready > div > label.required.je-form-input-label {
    padding-bottom: 0.5rem;
    margin-left: 0;
    visibility: hidden;
    &::before {
      visibility: visible;
      content: "System";
    }
  }

  & // object titles
    div
    div
    div.je-ready
    div
    div
    div.je-child-editor-holder.je-object__container
    div.je-indented-panel
    div
    div
    div.row
    div.je-object__container
    div.je-indented-panel
    div
    div
    div.row
    div.je-object__container
    h3.je-header.je-object__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    font-size: 0.9rem;
    font-weight: 400;
    color: $ui-color;
    background-color: lighten($ui-color-border, 12%);
    border-left: solid 3px $ui-color;
    padding: 0.4rem 0.8rem;
    border-radius: $border-radius;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    & label {
      color: black;
      font-size: 1rem;
      font-weight: 400;
      margin-left: 1.3rem;
    }
  }

  & // object titles help
  > div
  > div
  > div.je-ready
  > div
  > div
  > div.je-child-editor-holder.je-object__container
  > div.je-indented-panel
  > div
  > div
  > div.row
  > div.je-object__container
  > div.je-indented-panel
  > div
  > div
  > div.row
  > div.je-object__container
  > p {
    display: block;
    @include common-description-style($ui-color-border);
    margin-left: 0.2rem;
    margin-top: 0.1rem;
  }

  & // panel
    div
    div
    div.je-ready
    div
    div
    div.je-child-editor-holder.je-object__container
    div.je-indented-panel {
    border: none;
    padding: 0rem;
    margin: 0;
    margin-top: -1rem;
  }

  & // panel layout
    div
    div
    div.je-ready
    div
    div
    div.je-child-editor-holder.je-object__container
    div.je-indented-panel
    div
    div
    div.row
    div.je-object__container
    div.je-indented-panel
    div
    div {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    & > .row {
      width: 100%;
      min-width: 350px;
      height: fit-content;
      display: flex;
      padding: 0rem 0rem;

      & > div.je-object__container > div.je-indented-panel > div > div {
        display: flex;
        flex-direction: row;
        gap: 0.4rem 0.8rem;
        flex-wrap: wrap;
        padding-left: 1rem;
        border-left: solid 3px $ui-color;
        margin: 0.3rem 0;
        padding: 0.5rem 1rem;

        & .row {
          width: fit-content;
          border: none;
          padding: 0;
        }
      }
    }
  }

  & div.je-child-editor-holder .je-indented-panel .form-control > p {
    @include common-description-style($ui-color-border);
    text-transform: lowercase;
    margin-left: -0.5rem;
    margin-top: -0.3rem;
  }

  & .je-form-input-label,
  label {
    @include input-label-style;
    margin-bottom: 0px !important;
    font-size: $font-size-small;
    margin-left: 1.6rem;
  }

  input {
    @include input-style;
  }

  & select.je-switcher,
  select {
    @include input-style;
    width: 350px;
  }
}
