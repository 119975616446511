@mixin input-style {
  margin-bottom: 0px !important;
  padding: $padding-small;
  border: $border-style;
  border-radius: $border-radius;
  width: 350px;
  max-width: 400px;
  background-color: white !important;
  margin-left: 0 !important;
  font-style: normal;
  font-size: 0.9rem;
  transition: 0.05s ease-in-out;
  outline: none;

  &:hover {
    border-color: $hover-border-color;
  }
}

@mixin input-label-style {
  font-size: 0.875 !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}

@mixin upload-input-styles($margin-bottom: 0) {
  // form buttons
  .json-editor-btn- {
    background-color: white;
    border: solid 1px $ui-color;
    color: $ui-color;
    font-size: 0.95rem;
    border-radius: 0rem $border-radius $border-radius 0;
    padding: 0 0.4rem;
    height: 33.2px;
    margin-left: -4rem;
  }

  // upload preview
  .je-upload-preview {
    font-size: 0.9rem;
    padding: 0.8rem 0.2rem 0.6rem 0.2rem;
    color: rgb(74, 83, 97);
    strong {
      font-weight: 500;
    }
  }

  // upload button
  .json-editor-btn-upload {
    @extend .json-editor-btn-;
    border-radius: 0.3rem;
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
@mixin panel-layout-styles {
  &
    > div
    > div.je-child-editor-holder.je-object__container
    > div.je-indented-panel
    > div
    > div
    > div.row
    > div.je-object__container
    > div.je-indented-panel
    > div
    > div {
    display: flex;
    gap: 0.4rem 0.8rem;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 1rem 0.2rem 0.5rem 0.2rem;

    // panel selects
    & select {
      width: 200px;
    }
  }
}

@mixin hide-root-elements {
  & div > div > .je-ready > [data-schemapath="root"] > .je-header,
  &
    > div
    div
    div.je-ready
    div.je-object__container
    span.je-object__controls.form-control,
  & div div div.je-ready div p {
    display: none;
  }
}

@mixin hide-processing-single-panel-elements {
  // item label and object controls
  & > .je-form-input-label,
  > div
    div.je-child-editor-holder.je-object__container
    div.je-indented-panel
    div
    div
    div.row
    div.je-object__container
    .je-object__controls,
  > div > .je-child-editor-holder.je-object__container > .je-object__controls {
    display: none;
  }
  // inner toggle button
  &
    .je-indented-panel
    .je-object__container
    > .je-header.je-object__title
    > .json-editor-btn-collapse.json-editor-btntype-toggle {
    display: none;
  }

  &
    > div
    > div.je-child-editor-holder.je-object__container
    > div.je-indented-panel
    > div
    > div
    > div.row
    > div.je-object__container
    > div.je-indented-panel
    > div
    > div
    > div.row
    > div
    > h3.je-header
    > button.json-editor-btn-collapse.json-editor-btntype-toggle {
    display: none;
  }
  &
    > div
    > div.je-child-editor-holder.je-object__container
    > div.je-indented-panel
    > div
    > div
    > div.row
    > div.je-object__container
    > div.je-indented-panel
    > div
    > div
    > div.row
    > div
    > h3.je-header
    > span {
    display: none;
  }
}

@mixin toggle-button-styles {
  // toggle button position
  & h3.je-header.je-object__title {
    position: absolute;
    width: 15px;
    margin-top: -2.2rem !important;
    right: 0;
    top: 0;
  }

  // toggle button
  & .json-editor-btn-collapse.json-editor-btntype-toggle {
    border: solid $ui-color 3px !important;
    color: $ui-color;
    padding: 0 0.2rem;
    border-radius: $border-radius;
    box-shadow: none !important;
    font-size: $font-size-small;
    margin: 0 !important;
    visibility: hidden;
    width: 15px;
    font-size: 1rem;
    &::after {
      content: "\25BC";
      visibility: visible;
      position: absolute;
      width: fit-content;
      top: 0;
      right: 0;
    }
    &:hover {
      color: darken($ui-color, 10%);
    }
  }
}

@mixin move-button-style($rotation-angle: 0deg) {
  color: #222;
  font-size: $font-size-small;
  visibility: hidden;
  width: 16px;
  font-size: 1rem;
  &::before {
    content: "\21E4";
    display: inline-block;
    transform: rotate($rotation-angle);
    visibility: visible;
  }

  &:hover {
    opacity: 0.8;
  }
}

@mixin common-description-style($color: $ui-color-border) {
  display: block;
  font-size: $font-size-small;
  color: $color;
  font-weight: 400 !important;
  background-color: darken($ui-color, 20%);
  border-radius: $border-radius;
  padding: $padding-small;
  width: 230px;
  position: absolute;
  visibility: hidden;
  z-index: 3000;
  margin-left: -0.5rem;
  margin-top: -0.3rem;
  box-shadow: 0px 2px 10px #d7d7d7;
  word-break: break-all;

  &::before {
    content: "?";
    color: $ui-color;
    border: solid 1.5px $ui-color-border;
    padding: 0 0.25rem;
    border-radius: 0.2rem;
    visibility: visible;
    position: relative;
    margin-right: 0.5rem;
    font-size: 0.75rem;
    font-weight: 500;
    right: 0;
    position: relative;
    z-index: 1000;
  }

  &:hover {
    visibility: visible;
  }
  &:hover::before {
    color: #d1d5db;
  }
}
