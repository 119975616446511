@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

.gradient-background {
  background: radial-gradient(circle at 10% 20%, #111, transparent 70%),
    radial-gradient(circle at 90% 80%, #222, transparent 70%),
    radial-gradient(circle at 100% 0, #064e3b, transparent 70%),
    radial-gradient(circle at 80% 20%, #10b981, transparent 70%),
    radial-gradient(circle at 20% 60%, #10b981, transparent 70%),
    radial-gradient(circle at 0 100%, #ef4444, transparent 70%),
    linear-gradient(135deg, #000000, #1a1a2e, #16213e, #0f3460, #1c1c2b);
}

.gradient-background-faded {
  background: radial-gradient(circle at 20% 50%, #232323, transparent 20%),
    linear-gradient(45deg, #232323, #1b1b1b, #1c1c1c, #202020);
}

.draggable-item {
  transition: transform 0.2s ease;
}

.over {
  border: 1.5px dashed #666;
}

.dragging {
  opacity: 0.5;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.loading .h-3:nth-child(1) {
  animation: blink 1.5s infinite;
}

.loading .h-3:nth-child(2) {
  animation: blink 1.5s infinite 0.33s;
}

.loading .h-3:nth-child(3) {
  animation: blink 1.5s infinite 0.66s;
}
