.beryl_sidenav {
  overflow: visible;
  z-index: 900;
}

.sidebar {
  transition: width 0.4s ease-in-out;
  position: sticky;
  top: 0;
  z-index: 800;
  width: 100%;
  overflow: visible;

  &__main {
    height: fit-content;
    overflow: scroll;
  }
  &__element-info {
    margin-top: auto;
    flex-grow: 1;
    height: fit-content;
    padding: 1rem;
  }
  .element-title {
    white-space: nowrap;
  }
  .group-options-menu {
    margin-top: 1.8rem;
    margin-right: -8rem;
  }
}

// override splitpane color
.split-sash-content.split-sash-content-active.split-sash-content-vscode {
  background-color: rgb(69, 69, 72) !important;
}

.react-split__pane {
  overflow: visible !important;
}
