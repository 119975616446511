@mixin menu-base {
  @apply bg-zinc-800/70 backdrop-blur-md border border-zinc-700/80 text-zinc-400 rounded-md shadow-lg transition ease-in-out;
}

@mixin common-ui {
  @apply absolute flex gap-1 z-50 h-fit;
}

.beryl_ui_map_menu {
  @include menu-base;
  @include common-ui;

  &__item {
    @include menu-base;
    @apply p-1;
    transition: all 0.2s ease-in-out;
    overflow: hidden;

    &--layers {
      width: 100%;
      max-width: 300px;
      overflow: auto;
      padding: 0.1rem 0.2rem;
      font-size: 0.875rem;
      @apply border-t border-zinc-600 mt-1;
    }
  }
}

.beryl_ui_map_menu_wrapper {
  height: 94vh;
  overflow: hidden;
  position: absolute;
  z-index: 10;
  color: white;
  font-size: 0.9rem;

  &__inner {
    height: inherit;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
    padding: 0.7rem;

    > div {
      display: flex;
      flex-direction: column;
      height: fit-content;
      overflow: hidden;
    }
  }
}
