@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-bg: #f3f4f6;
    --color-purple: rgb(147, 45, 255);
  }
}

@layer components {
  @import "nicejsonform";
  @import "map/menu";

  .beryl_project_workspace {
    display: flex;
    height: 100vh;
    overflow: hidden;
    @apply bg-emerald-800;
  }

  .tooltip-button {
    @apply text-gray-700 hover:border-emerald-800 p-1 rounded hover:bg-emerald-800/10 flex justify-center gap-1 items-center w-8 h-8;
    &--text {
      @extend .tooltip-button;
      @apply p-2;
      width: fit-content;
      height: fit-content;
    }
  }
}

@import "text-editor";
@import "sidebar";
@import "layout";
@import "form";
@import "effects";
@import "jsoneditor/import";
@import "jsoneditor/processing";
@import "jsoneditor/inversion";
